import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTransition, animated } from "react-spring";

import { pointHistory } from "../../../../services/Api/Module/Customer";

import PageTitleMember from "../../../../layouts/inc/PageTitleMember";
import CardBurnPointHistory from "../../../../components/card/history/point/CardBurnPointHistory";
import ModalPointHistoryFilter from "../../../../components/modal/history/point/ModalPointHistoryFilter";
import MenuMember from "../../../../components/menu/MenuMember";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const MyPointHistory = ({ articleItem }) => {
  let height = 0;

  const transitions = useTransition(
    articleItem.map((data) => ({
      ...data,
      y:
        (height += data.props.curHeight ? data.props.curHeight + 16 : 180) -
        (data.props.curHeight ? data.props.curHeight + 16 : 180),
    })),
    {
      key: (item) => item.key,
      from: { height: 0, opacity: 0, transform: "translateY(30px)" },
      enter: ({ y, height }) => ({
        y,
        height,
        opacity: 1,
        transform: "translateY(0)",
      }),
      leave: { height: 0, opacity: 0, transform: "translateY(30px)" },
      update: ({ data, y, height }) => ({ data, y, height }),
    }
  );

  return (
    <div
      className="item-group"
      style={{ position: "relative", height: height }}
    >
      {transitions((style, item, t, index) => (
        <animated.div
          className="item-point-history"
          style={{
            ...style,
            zIndex: articleItem.length - index,
            willChange: "transform, height, opacity",
          }}
        >
          {item}
        </animated.div>
      ))}
    </div>
  );
};

const BurnPointHistory = () => {
  const stateProfile = useSelector((state) => state.profile);

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* Modal filter */
  const [configYear, setConfigYear] = useState({
    maxYear: moment().year(),
    minYear: moment().year(),
  });

  const [isModalShow, setIsModalShow] = useState(false);
  const [isFilterSubmit, setIsFilterSubmit] = useState(false);

  const handleOpenModalFilter = (e) => {
    e.preventDefault();

    setIsModalShow(true);
  };

  const handleCloseModalFilter = () => {
    setIsModalShow(false);
  };

  const handleSubmitFilter = (dataFilter, submitFilter) => {
    // console.log("dataFilter!! >>>", dataFilter, submitFilter);
    const _year = dataFilter.year !== "" ? dataFilter.year : "";
    const _month = dataFilter.month !== "" ? parseInt(dataFilter.month) : "";

    if (submitFilter) {
      let payload = {
        customer: stateProfile.id,
        point_type: 2,
      };

      if (_year !== "" || _month !== "") {
        payload.date = `${_year !== "" ? _year : "0000"}-${
          _month !== "" ? _month.toString().padStart(2, "0") : "00"
        }`;
      }

      getPointHistory(payload).then((_rsDataPointHistory) => {
        // console.log("_rsDataPointHistory >>", _rsDataPointHistory);

        setInitItem([]);
        setCurHeight([]);

        setPointHistoryItem([]);
        setArticleItem([]);

        if (
          _rsDataPointHistory.status === true &&
          _rsDataPointHistory.result.length !== 0
        ) {
          const _length = _rsDataPointHistory.result.pointHistory.length;
          const _pointHistory = _rsDataPointHistory.result.pointHistory;
          // console.log(_pointHistory);

          for (let i = 0; i < _length; i++) {
            setInitItem((prevInitItem) => [...prevInitItem, _pointHistory[i]]);
          }
        }
      });

      setIsFilterSubmit(true);
    }
  };

  useEffect(() => {
    // console.log(isFilterSubmit);
  }, [isFilterSubmit]);
  /* End Modal filter */

  /* First event render */
  const [isLoading, setIsLoading] = useState(true);

  const [initItem, setInitItem] = useState([]);
  const [initHeight, setInitHeight] = useState([]);
  const [curHeight, setCurHeight] = useState([]);

  const [pointHistoryItem, setPointHistoryItem] = useState([]);
  const [articleItem, setArticleItem] = useState([]);

  const getPointHistory = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await pointHistory({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getPointHistory resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getPointHistory >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (stateProfile.id === 0) {
      navigate("/");
    } else {
      getPointHistory({
        customer: stateProfile.id,
        point_type: 2,
      }).then((_rsDataPointHistory) => {
        // console.log("_rsDataPointHistory >>", _rsDataPointHistory);

        if (
          _rsDataPointHistory.status === true &&
          _rsDataPointHistory.result.length !== 0
        ) {
          if (
            typeof _rsDataPointHistory.result.pointHistory !== "undefined" &&
            _rsDataPointHistory.result.pointHistory.length > 0
          ) {
            const _length = _rsDataPointHistory.result.pointHistory.length;
            const _pointHistory = _rsDataPointHistory.result.pointHistory;
            // console.log(_pointHistory);

            for (let i = 0; i < _length; i++) {
              setInitItem((prevInitItem) => [
                ...prevInitItem,
                _pointHistory[i],
              ]);

              setInitHeight((prevCurHeight) => [...prevCurHeight, 0]);
            }
          }

          if (typeof _rsDataPointHistory.result.year !== "undefined") {
            setConfigYear({
              maxYear: _rsDataPointHistory.result.year.maxYear,
              minYear: _rsDataPointHistory.result.year.minYear,
            });
          }
        }

        setIsLoading(false);
      });
    }
  }, []);
  /* End First event render */

  /* Set new height on resize */
  let _tmpHeight = [];

  const setNewHeight = (index, height) => {
    // console.log(index, height);
    if (height !== 0) {
      _tmpHeight[index] = height;
      setCurHeight(_tmpHeight);
    }
  };
  /* End Set new height on resize */

  useEffect(() => {
    // console.log(initItem.length, initItem);

    if (initItem.length > 0) {
      for (let i = 0; i < initItem.length; i++) {
        setPointHistoryItem((prevPointHistoryItem) => [
          ...prevPointHistoryItem,
          <CardBurnPointHistory
            key={i + 1}
            index={i}
            setNewHeight={setNewHeight}
            curHeight={curHeight[i]}
            year={moment(
              initItem[i].createdDate
                ? initItem[i].createdDate
                : initItem[i].createdOn
            ).year()}
            month={moment(
              initItem[i].createdDate
                ? initItem[i].createdDate
                : initItem[i].createdOn
            ).format("M")}
            transactionNo={initItem[i].transactionNo}
            createdDate={moment(
              initItem[i].createdDate
                ? initItem[i].createdDate
                : initItem[i].createdOn
            ).format("DD/MM/YYYY HH:mm:ss")}
            createdDateTh={moment(
              initItem[i].createdDate
                ? initItem[i].createdDate
                : initItem[i].createdOn
            ).format(
              "DD/MM/" +
                (parseInt(
                  moment(
                    initItem[i].createdDate
                      ? initItem[i].createdDate
                      : initItem[i].createdOn
                  ).format("YYYY")
                ) +
                  543)
              // +
              // " " +
              // moment(
              //   initItem[i].createdDate
              //     ? initItem[i].createdDate
              //     : initItem[i].createdOn
              // ).format("HH:mm:ss")
            )}
            branch={initItem[i].branch}
            totalPrice={initItem[i].totalPrice}
            point={parseInt(initItem[i].point)}
            dataPointHistory={initItem[i]}
          />,
        ]);

        _tmpHeight[i] = initHeight[i];
      }
    }
  }, [initItem]);

  useEffect(() => {
    if (pointHistoryItem) {
      // console.log(pointHistoryItem.length, pointHistoryItem);

      setArticleItem(pointHistoryItem);
    }
  }, [pointHistoryItem]);

  useEffect(() => {
    // console.log(curHeight.length, curHeight);

    if (curHeight.length > 0) {
      curHeight.map((val, idx) => {
        setPointHistoryItem((prevPointHistoryItem) =>
          prevPointHistoryItem.with(
            idx,
            <CardBurnPointHistory
              key={pointHistoryItem[idx].key}
              index={idx}
              setNewHeight={setNewHeight}
              curHeight={val}
              year={moment(
                pointHistoryItem[idx].props.dataPointHistory.createdDate
                  ? pointHistoryItem[idx].props.dataPointHistory.createdDate
                  : pointHistoryItem[idx].props.dataPointHistory.createdOn
              ).year()}
              month={moment(
                pointHistoryItem[idx].props.dataPointHistory.createdDate
                  ? pointHistoryItem[idx].props.dataPointHistory.createdDate
                  : pointHistoryItem[idx].props.dataPointHistory.createdOn
              ).format("M")}
              transactionNo={pointHistoryItem[idx].props.transactionNo}
              createdDate={pointHistoryItem[idx].props.createdDate}
              createdDateTh={pointHistoryItem[idx].props.createdDateTh}
              branch={pointHistoryItem[idx].props.branch}
              totalPrice={pointHistoryItem[idx].props.totalPrice}
              point={pointHistoryItem[idx].props.point}
              dataPointHistory={pointHistoryItem[idx].props.dataPointHistory}
            />
          )
        );
      });
    }
  }, [curHeight]);

  useEffect(() => {
    // console.log(articleItem.length, articleItem);
  }, [isLoading, articleItem, isModalShow]);

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageBurnPointHistory">
        <main>
          <div className="wrapContent">
            <PageTitleMember title="ประวัติคะแนน Healthup" />

            <ModalPointHistoryFilter
              open={isModalShow}
              onClose={handleCloseModalFilter}
              maxYear={configYear.maxYear}
              minYear={configYear.minYear}
              submitCallback={handleSubmitFilter}
            />

            <div className="bgList">
              <section id="pFilterMain">
                <div className="bSubmenu">
                  <a
                    className=""
                    href="#"
                    onClick={handleGotoPage}
                    data-page="earn-point-history"
                  >
                    คะแนนที่ได้รับ
                  </a>
                  <a
                    className="active"
                    href="#"
                    onClick={handleGotoPage}
                    data-page="burn-point-history"
                  >
                    คะแนนที่ใช้งาน
                  </a>
                </div>
                <div className="bFilter">
                  <p className="btnFilter" onClick={handleOpenModalFilter}>
                    ทั้งหมด
                  </p>
                </div>
              </section>
            </div>
            <section id="pBurnPointHistoryMain" style={{ paddingTop: "unset" }}>
              <div className="bBurnPointHistory">
                {isLoading ? (
                  <div className="bNoData">
                    <p>
                      <FontAwesomeIcon icon={faSpinner} spin /> Loading...
                    </p>
                  </div>
                ) : (
                  <>
                    {articleItem.length === 0 ? (
                      <>
                        {isFilterSubmit ? (
                          <div className="bNoData">
                            <p>{`ไม่มีประวัติค้นหาคะแนนที่ใช้งาน`}</p>
                          </div>
                        ) : (
                          <div className="bNoData">
                            <p>{`ไม่มีประวัติคะแนนที่ใช้งาน`}</p>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="bGroup ">
                        <MyPointHistory articleItem={articleItem} />
                      </div>
                    )}
                  </>
                )}
              </div>
            </section>

            <MenuMember pageActive="profile" />
          </div>
        </main>
      </div>
    </>
  );
};

export default BurnPointHistory;

import { useEffect, useState } from "react";

import jBox from "jbox";
import $ from "jquery";

function ModalConfirmSurvey({
  open = false,
  onClose = () => {},
  submitCallback = () => {},
}) {
  const [modal, setModal] = useState(null);
  const [clickClose, setClickClose] = useState(false); //block twic click close
  const [clickSubmit, setClickSubmit] = useState(false); //block twic click submit

  const openModal = () => {
    modal.open();
  };

  const closeModal = () => {
    modal.close();
    onClose();
  };

  const listenerCloseModal = () => {
    if (!clickClose) {
      const btnCancel = document.getElementById("btnCancelSurvey");
      btnCancel.addEventListener("click", () => {
        closeModal();
        setClickClose(true);
      });
    }
  };

  const listenerConfirm = () => {
    if (!clickSubmit) {
      const btnConfirm = document.getElementById("btnConfirmSurvey");
      btnConfirm.addEventListener("click", () => {
        submitCallback(true);
        closeModal();
        setClickSubmit(true);
      });
    }
  };

  /* First event render */
  useEffect(() => {
    const initModal = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalConfirm"),
    });
    setModal(initModal);

    return () => {
      initModal.destroy();
    };
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log("modalQrCode useEffect2!! >>", modalQrCode, open);

    if (modal && open) {
      openModal();

      listenerCloseModal();
      listenerConfirm();
    }
  }, [modal, open]);

  return (
    <div className="bModal bCard" id="ModalConfirm">
      <div className="bInner">
        <div className="bTitle">
          <span>ยืนยันคำตอบ</span>
        </div>
        <div className="bBtn">
          <button className="btn" id="btnConfirmSurvey">
            ยืนยัน
          </button>
          <button className="btn outline btnCloseModal" id="btnCancelSurvey">
            ย้อนกลับ
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalConfirmSurvey;

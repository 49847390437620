import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { resetSurvey } from "../../../features/SurveySlice";
import { createSurvey } from "../../../services/Api/Module/Customer";

import { breakKeyDownEnter } from "../../../services/helper";

import img_icoLogo from "../../../assets/img/ci/icon-profile/ico-logo.svg";

import ModalConfirmSurvey from "../../../components/modal/survey/ModalConfirmSurvey";

const SurveyForm = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);
  const stateSurvey = useSelector((state) => state.survey);

  const resetDataSurvey = () => {
    dispatch(resetSurvey()); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* Form */
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
  });

  // useEffect(() => {
  //   console.log(isValid);
  // }, [isValid]);
  /* End Form */

  /* Handle click submit form */
  const postCreateSurvey = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await createSurvey({ data: payload });
      // console.log("data.body >>", data);
      if (data.resultCode === 20201) {
        // const resData = data.body;
        // console.log("postCreateSurvey resData >>", resData);

        _return.status = true;
        // _return.result = resData;
      } else {
        console.warn(data);
      }
    } catch (e) {
      console.log("ERR postCreateSurvey >> ", e);
    }

    return _return;
  };

  const [formPayload, setFormPayload] = useState({});

  const onSubmit = (data) => {
    // console.log("onSubmit", data);

    let payload = {
      surveyId: stateSurvey.id,
      custId: stateProfile.id,
      responseAnswer: [],
    };

    Object.entries(data.q).forEach(([key, val]) => {
      // console.log(key, val, Array.isArray(val));

      if (Array.isArray(val)) {
        payload.responseAnswer = [
          ...payload.responseAnswer,
          {
            questionId: stateSurvey.data.questionList[key].questionId,
            type: stateSurvey.data.questionList[key].type,
            answers: [],
          },
        ];

        Object.entries(val).forEach(([k, v]) => {
          // console.log(k, v);

          const _textVal = data[`q_text_${key}_${v}`];
          // console.log(_textVal);

          // console.log(payload.responseAnswer[key]);
          if (stateSurvey.data.questionList[key].type === 3) {
            payload.responseAnswer[key].answers = [
              ...payload.responseAnswer[key].answers,
              {
                answerId:
                  stateSurvey.data.questionList[key].answerChoices[v].answerId,
                answerText: typeof _textVal !== "undefined" ? _textVal : null,
                selectionOrder: document.querySelector(
                  `.checked-sequence[data-sq-idx="${key}"][data-sa-idx="${v}"]`
                ).dataset.seq,
              },
            ];
          } else {
            payload.responseAnswer[key].answers = [
              ...payload.responseAnswer[key].answers,
              {
                answerId:
                  stateSurvey.data.questionList[key].answerChoices[v].answerId,
                answerText: typeof _textVal !== "undefined" ? _textVal : null,
              },
            ];
          }
        });
      } else {
        const _textVal = data[`q_text_${key}_${val}`];
        // console.log(_textVal);

        payload.responseAnswer = [
          ...payload.responseAnswer,
          {
            questionId: stateSurvey.data.questionList[key].questionId,
            type: stateSurvey.data.questionList[key].type,
            answers: [
              {
                answerId:
                  stateSurvey.data.questionList[key].answerChoices[val]
                    .answerId,
                answerText: typeof _textVal !== "undefined" ? _textVal : null,
              },
            ],
          },
        ];
      }
    });
    // console.log(payload);

    // postCreateSurvey(payload).then((_rsData) => {
    //   // console.log(_rsData);

    //   if (_rsData.status === true) {
    //     navigate("/survey/complete");
    //   }
    // });

    setFormPayload(payload);

    setIsModalConfirmShow(true);
  };
  /* End Handle click submit form */

  /* Modal confirm */
  const [isModalConfirmShow, setIsModalConfirmShow] = useState(false);

  const handleOpenModalConfirm = (e) => {
    e.preventDefault();
    setIsModalConfirmShow(true);
  };

  const handleCloseModalConfirm = () => {
    setIsModalConfirmShow(false);
  };

  const [modalClickSubmit, setModalClickSubmit] = useState(false);

  const handleSurveyConfirm = (submit) => {
    // console.log(submit);

    if (!modalClickSubmit && submit) {
      setModalClickSubmit(true);
    }
  };

  useEffect(() => {
    if (modalClickSubmit) {
      // console.log(modalClickSubmit);

      if (Object.getOwnPropertyNames(formPayload).length !== 0) {
        postCreateSurvey(formPayload).then((_rsData) => {
          // console.log(_rsData);

          if (_rsData.status === true) {
            navigate("/survey/complete");
          }
        });
      }
    }
  }, [modalClickSubmit]);

  useEffect(() => {
    if (!isModalConfirmShow) {
      setModalClickSubmit(false);
    }
  }, [isModalConfirmShow]);
  /* End Modal confirm */

  /* Handle checkbox on change */
  const handleCheckboxChange = (ele) => {
    const { name, value, checked } = ele.target;
    // console.log("Checkbox is now:", checked, name, value);

    const questionIdx = name.replace("q.", "");
    const answerIdx = value;

    const _textArea = document.querySelector(
      `textarea[name="q_text_${questionIdx}_${answerIdx}"]`
    );
    // console.log(_textArea);

    if (_textArea !== null) {
      if (checked) {
        _textArea.disabled = false;
        _textArea.placeholder = "กรุณาระบุ";
      } else {
        _textArea.disabled = true;
        _textArea.placeholder = "";
      }
    }

    const currentValues = getValues(name) || [];

    if (checked) {
      setValue(name, [...currentValues, value], { shouldValidate: true });

      clearErrors(name);
    } else {
      setValue(
        name,
        currentValues.filter((item) => item !== value),
        { shouldValidate: true }
      );
    }
  };
  /* End Handle checkbox on change */

  /* Handle radio on change */
  const handleRadioChange = (ele) => {
    const { name, value, checked } = ele.target;
    // console.log("radio is now:", checked, name, value);

    const questionIdx = name.replace("q.", "");
    const answerIdx = value;

    const _textAreaGroup = document.querySelectorAll(
      `textarea[name^="q_text_${questionIdx}"]`
    );
    if (_textAreaGroup.length > 0) {
      for (let i = 0; i < _textAreaGroup.length; i++) {
        _textAreaGroup[i].disabled = true;
        _textAreaGroup[i].placeholder = "";
      }
    }

    const _textArea = document.querySelector(
      `textarea[name="q_text_${questionIdx}_${answerIdx}"]`
    );
    // console.log(_textArea);

    if (_textArea !== null) {
      if (checked) {
        _textArea.disabled = false;
        _textArea.placeholder = "กรุณาระบุ";
      } else {
        _textArea.disabled = true;
      }
    }

    if (checked) {
      setValue(name, value, { shouldValidate: true });

      clearErrors(name);
    }
  };
  /* End Handle radio on change */

  /* Handle checkbox sequence on change */
  const handleCheckboxSeqChange = (ele) => {
    const { name, value, checked } = ele.target;
    // console.log("Checkbox seq is now:", checked, name, value);

    const questionIdx = name.replace("q.", "");
    const answerIdx = value;

    const _textArea = document.querySelector(
      `textarea[name="q_text_${questionIdx}_${answerIdx}"]`
    );
    // console.log(_textArea);

    if (_textArea !== null) {
      if (checked) {
        _textArea.disabled = false;
        _textArea.placeholder = "กรุณาระบุ";
      } else {
        _textArea.disabled = true;
        _textArea.placeholder = "";
      }
    }

    const _eleSeq = document.querySelectorAll(
      `.checked-sequence[data-sq-idx="${questionIdx}"]`
    );
    // console.log("_eleSeq >>", _eleSeq);
    const _seqVal = Array.from(_eleSeq).map((el) =>
      el.getAttribute("data-seq")
    );
    // console.log("_seqVal >>", _seqVal);
    const _seqCount = _seqVal.reduce(function (n, val) {
      return n + (val !== "");
    }, 0);
    // console.log("_seqCount >>", _seqCount);

    const currentValues = getValues(name) || [];

    if (checked) {
      const tmp = document.querySelector(
        `.checked-sequence[data-sq-idx="${questionIdx}"][data-sa-idx="${answerIdx}"]`
      );
      tmp.dataset.seq = `${_seqCount + 1}`;
      tmp.innerHTML = `${_seqCount + 1}`;

      if (_seqCount + 1 >= 3) {
        const _seqEleAll = document.querySelectorAll(
          `[type="checkbox"][data-sq-idx="${questionIdx}"]`
        );
        // console.log("_seqEleAll >>", _seqEleAll);

        for (let i = 0; i < _seqEleAll.length; i++) {
          if (!_seqEleAll[i].checked) {
            _seqEleAll[i].disabled = true;
          }
        }
      }

      setValue(name, [...currentValues, value], { shouldValidate: true });

      clearErrors(name);
    } else {
      const tmp = document.querySelector(
        `.checked-sequence[data-sq-idx="${questionIdx}"][data-sa-idx="${answerIdx}"]`
      );
      tmp.dataset.seq = "";
      tmp.innerHTML = "";

      const _seqEleAll = document.querySelectorAll(
        `[type="checkbox"][data-sq-idx="${questionIdx}"]`
      );
      // console.log("_seqEleAll >>", _seqEleAll);

      for (let i = 0; i < _seqEleAll.length; i++) {
        if (!_seqEleAll[i].checked) {
          _seqEleAll[i].disabled = false;
        }
      }

      if (_seqCount <= 3) {
        const _eleSeq = document.querySelectorAll(
          `.checked-sequence[data-sq-idx="${questionIdx}"]`
        );
        // console.log("_eleSeq >>", _eleSeq);
        const _seqVal = Array.from(_eleSeq).map((el) =>
          el.getAttribute("data-seq")
        );
        // console.log("_seqVal >>", _seqVal);
        const _seqSort = _seqVal.filter((item) => item).sort();
        // console.log("_seqSort >>", _seqSort);

        for (let i = 0; i < _eleSeq.length; i++) {
          for (let k = 0; k < _seqSort.length; k++) {
            if (_eleSeq[i].dataset.seq === _seqSort[k]) {
              _eleSeq[i].dataset.seq = `${k + 1}`;
              _eleSeq[i].innerHTML = `${k + 1}`;
            }
          }
        }
      }

      setValue(
        name,
        currentValues.filter((item) => item !== value),
        { shouldValidate: true }
      );
    }
  };
  /* End Handle checkbox sequence on change */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateProfile, stateSurvey);
    // console.log("useEffect !", Object.getOwnPropertyNames(stateProfile.dataUser).length);

    if (
      stateProfile.id === 0 ||
      Object.getOwnPropertyNames(stateProfile.dataUser).length === 0 ||
      Object.getOwnPropertyNames(stateSurvey.data).length === 0
    ) {
      navigate("/");
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageSurvey-Form">
        <main>
          <div className="wrapContent">
            <ModalConfirmSurvey
              open={isModalConfirmShow}
              onClose={handleCloseModalConfirm}
              submitCallback={handleSurveyConfirm}
            />

            <section id="pTiltlePage">
              <div className="bLogo">
                <img src={img_icoLogo} />
              </div>
            </section>
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => breakKeyDownEnter(e)}
            >
              <section id="pContentMain">
                <div className="bTitle">
                  <h1>{stateSurvey.data?.surveyName}</h1>
                </div>
                <div className="bSurvey">
                  {stateSurvey.data?.questionList &&
                    stateSurvey.data.questionList.map((sqItem, sqIndex) => (
                      <article key={sqIndex + 1}>
                        <div className="bQuestionMain">
                          {sqItem.questionImage !== null && (
                            <div className="bPic">
                              <img src={sqItem.questionImage} alt="" />
                            </div>
                          )}
                          <div className="bQuestion">
                            <span>ข้อที่ {sqIndex + 1} :</span>
                            <div>
                              <h3>{sqItem.questionName}</h3>
                              {sqItem.type === 1 ? (
                                <p>(เลือกตอบได้มากกว่า 1 ข้อ)</p>
                              ) : (
                                sqItem.type === 3 && (
                                  <p>(โปรดจัดอันดับ (1-3) ตามความพอใจ)</p>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="bAnswerMain">
                          <div className="bForm">
                            {sqItem.type === 1 && (
                              <div
                                className={`control-group ${
                                  errors[`q`] && errors[`q`][sqIndex]
                                    ? "error"
                                    : ""
                                }`}
                              >
                                {sqItem?.answerChoices &&
                                  sqItem?.answerChoices.map(
                                    (saItem, saIndex) => (
                                      <div
                                        className="bChoice"
                                        key={saIndex + 1}
                                      >
                                        <label className="control control--checkbox">
                                          {saItem.answerName}
                                          <input
                                            {...register(`q.${sqIndex}`, {
                                              required: "กรุณาเลือก",
                                            })}
                                            type="checkbox"
                                            value={saIndex}
                                            onChange={(e) =>
                                              handleCheckboxChange(e)
                                            }
                                          />
                                          <div className="control__indicator"></div>
                                        </label>
                                        {saItem.answerImage !== null && (
                                          <div className="bPic">
                                            <img
                                              src={saItem.answerImage}
                                              alt=""
                                            />
                                          </div>
                                        )}
                                        {saItem.isText === 1 && (
                                          <div className="bTextarea">
                                            <textarea
                                              {...register(
                                                `q_text_${sqIndex}_${saIndex}`,
                                                {
                                                  required:
                                                    Array.isArray(
                                                      watch(`q.${sqIndex}`, [])
                                                    ) &&
                                                    watch(
                                                      `q.${sqIndex}`,
                                                      []
                                                    ).includes(`${saIndex}`)
                                                      ? "กรุณาระบุ"
                                                      : false,
                                                }
                                              )}
                                              disabled
                                              onChange={(e) => {
                                                setValue(
                                                  `q_text_${sqIndex}_${saIndex}`,
                                                  e.target.value,
                                                  { shouldValidate: true }
                                                );

                                                if (e.target.value !== "") {
                                                  clearErrors(`q.${sqIndex}`);
                                                }
                                              }}
                                              maxLength="150"
                                            ></textarea>
                                            {/* {errors[
                                              `q_text_${sqIndex}_${saIndex}`
                                            ] && (
                                              <label
                                                htmlFor=""
                                                className="tError"
                                              >
                                                {
                                                  errors[
                                                    `q_text_${sqIndex}_${saIndex}`
                                                  ].message
                                                }
                                              </label>
                                            )} */}
                                          </div>
                                        )}
                                      </div>
                                    )
                                  )}
                                {errors[`q`] && errors[`q`][sqIndex] && (
                                  <label htmlFor="" className="tError">
                                    {errors[`q`][sqIndex].message}
                                  </label>
                                )}
                              </div>
                            )}
                            {sqItem.type === 2 && (
                              <div
                                className={`control-group ${
                                  errors[`q`] && errors[`q`][sqIndex]
                                    ? "error"
                                    : ""
                                }`}
                              >
                                {sqItem?.answerChoices &&
                                  sqItem?.answerChoices.map(
                                    (saItem, saIndex) => (
                                      <div
                                        className="bChoice"
                                        key={saIndex + 1}
                                      >
                                        <label className="control control--radio">
                                          {saItem.answerName}
                                          <input
                                            {...register(`q.${sqIndex}`, {
                                              required: "กรุณาเลือก",
                                            })}
                                            type="radio"
                                            value={saIndex}
                                            onChange={(e) =>
                                              handleRadioChange(e)
                                            }
                                          />
                                          <div className="control__indicator"></div>
                                        </label>
                                        {saItem.answerImage !== null && (
                                          <div className="bPic">
                                            <img
                                              src={saItem.answerImage}
                                              alt=""
                                            />
                                          </div>
                                        )}
                                        {saItem.isText === 1 && (
                                          <div className="bTextarea">
                                            <textarea
                                              {...register(
                                                `q_text_${sqIndex}_${saIndex}`,
                                                {
                                                  required:
                                                    watch(`q.${sqIndex}`) ===
                                                    `${saIndex}`
                                                      ? "กรุณาระบุ"
                                                      : false,
                                                }
                                              )}
                                              disabled
                                              onChange={(e) => {
                                                setValue(
                                                  `q_text_${sqIndex}_${saIndex}`,
                                                  e.target.value,
                                                  { shouldValidate: true }
                                                );

                                                if (e.target.value !== "") {
                                                  clearErrors(`q.${sqIndex}`);
                                                }
                                              }}
                                              maxLength="150"
                                            ></textarea>
                                            {/* {errors[
                                              `q_text_${sqIndex}_${saIndex}`
                                            ] && (
                                              <label
                                                htmlFor=""
                                                className="tError"
                                              >
                                                {
                                                  errors[
                                                    `q_text_${sqIndex}_${saIndex}`
                                                  ].message
                                                }
                                              </label>
                                            )} */}
                                          </div>
                                        )}
                                      </div>
                                    )
                                  )}
                                {errors[`q`] && errors[`q`][sqIndex] && (
                                  <label htmlFor="" className="tError">
                                    {errors[`q`][sqIndex].message}
                                  </label>
                                )}
                              </div>
                            )}
                            {sqItem.type === 3 && (
                              <div
                                className={`control-group ${
                                  errors[`q`] && errors[`q`][sqIndex]
                                    ? "error"
                                    : ""
                                }`}
                                data-sq-idx={sqIndex}
                              >
                                {sqItem?.answerChoices &&
                                  sqItem?.answerChoices.map(
                                    (saItem, saIndex) => (
                                      <div
                                        className="bChoice"
                                        key={saIndex + 1}
                                      >
                                        <span
                                          className="checked-sequence"
                                          data-sq-idx={sqIndex}
                                          data-sa-idx={saIndex}
                                          data-seq=""
                                        ></span>
                                        <label className="control control--checkbox">
                                          {saItem.answerName}
                                          <input
                                            {...register(`q.${sqIndex}`, {
                                              validate: (value) =>
                                                value.length === 3 ||
                                                "กรุณาเลือก 3 ตัวเลือก",
                                            })}
                                            type="checkbox"
                                            value={saIndex}
                                            onChange={(e) =>
                                              handleCheckboxSeqChange(e)
                                            }
                                            data-sq-idx={sqIndex}
                                            data-sa-idx={saIndex}
                                          />
                                          <div className="control__indicator"></div>
                                        </label>
                                        {saItem.answerImage !== null && (
                                          <div className="bPic">
                                            <img
                                              src={saItem.answerImage}
                                              alt=""
                                            />
                                          </div>
                                        )}
                                        {saItem.isText === 1 && (
                                          <div className="bTextarea">
                                            <textarea
                                              {...register(
                                                `q_text_${sqIndex}_${saIndex}`,
                                                {
                                                  required:
                                                    Array.isArray(
                                                      watch(`q.${sqIndex}`, [])
                                                    ) &&
                                                    watch(
                                                      `q.${sqIndex}`,
                                                      []
                                                    ).includes(`${saIndex}`)
                                                      ? "กรุณาระบุ"
                                                      : false,
                                                }
                                              )}
                                              disabled
                                              onChange={(e) => {
                                                setValue(
                                                  `q_text_${sqIndex}_${saIndex}`,
                                                  e.target.value,
                                                  { shouldValidate: true }
                                                );

                                                if (e.target.value !== "") {
                                                  clearErrors(`q.${sqIndex}`);
                                                }
                                              }}
                                              maxLength="150"
                                            ></textarea>
                                            {/* {errors[
                                              `q_text_${sqIndex}_${saIndex}`
                                            ] && (
                                              <label
                                                htmlFor=""
                                                className="tError"
                                              >
                                                {
                                                  errors[
                                                    `q_text_${sqIndex}_${saIndex}`
                                                  ].message
                                                }
                                              </label>
                                            )} */}
                                          </div>
                                        )}
                                      </div>
                                    )
                                  )}
                                {errors[`q`] && errors[`q`][sqIndex] && (
                                  <label htmlFor="" className="tError">
                                    {errors[`q`][sqIndex].message}
                                  </label>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </article>
                    ))}
                </div>
              </section>

              <section id="pActionBtn">
                <div className="bBtn">
                  <button
                    className="btn outline"
                    onClick={(e) => {
                      resetDataSurvey();
                      handleGotoPage(e);
                    }}
                    data-page="survey"
                  >
                    ย้อนกลับ
                  </button>
                  <button className="btn" type="submit" disabled={!isValid}>
                    ต่อไป
                  </button>
                </div>
              </section>
            </form>
          </div>
        </main>
      </div>
    </>
  );
};

export default SurveyForm;

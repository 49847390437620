import { useEffect, useState } from "react";

import jBox from "jbox";
import $ from "jquery";

// import useTranslations from "../../i18n/useTranslations";

import img_icoXmark from "../../../../assets/img/ci/icon-modal/ico-xmark.svg";
// import img_imgQr from "../../assets/img/ci/icon-modal/img-qr.png";
// import img_imgBarcode from "../../assets/img/ci/icon-modal/img-barcode.png";

import QRCode from "react-qr-code";
import Barcode from "react-barcode";

function ModalProfileQrCode({
  open = false,
  onClose = () => {},
  custCode = null,
}) {
  const [modal, setModal] = useState(null);

  const openModal = () => {
    modal.open();
  };

  const closeModal = () => {
    modal.close();
    onClose();
  };

  const listenerCloseModal = () => {
    const btnCloseModal = document.getElementById("btnCloseModal");
    btnCloseModal.addEventListener("click", () => {
      closeModal();
    });
  };

  /* First event render */
  useEffect(() => {
    const initModal = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalConfirm"),
    });
    setModal(initModal);

    return () => {
      initModal.destroy();
    };
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log("modalQrCode useEffect2!! >>", modalQrCode, open);

    if (modal && open) {
      openModal();
      listenerCloseModal();
      // setTimeout(() => listenerCloseModal(), 100)
    }
  }, [modal, open]);

  return (
    <div className="bModal bCard" id="ModalConfirm">
      <div className="closeModal btnCloseModal" id="btnCloseModal">
        <img src={img_icoXmark} alt="" />
      </div>

      <div className="bInner zoomQr">
        {/* <img src={img_imgQr} alt="" /> */}
        <div className="wrpQrCode">
          {custCode !== null && (
            <QRCode size={168} value={custCode} viewBox={`0 0 168 168`} />
          )}
        </div>
        <p>กรุณาให้พนักงานสแกนที่หน้าร้านเท่านั้น</p>
        {/* <img src={img_imgBarcode} alt="" /> */}
        <div className="wrpBarCode">
          {custCode !== null && (
            <Barcode
              displayValue={false}
              value={custCode}
              width={2}
              height={55}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalProfileQrCode;

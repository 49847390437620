import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { resetSurvey } from "../../../features/SurveySlice";

import img_icoLogo from "../../../assets/img/ci/icon-profile/ico-logo.svg";

const SurveyHistory = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);
  const stateSurvey = useSelector((state) => state.survey);

  const resetDataSurvey = () => {
    dispatch(resetSurvey()); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateProfile, stateSurvey);
    // console.log("useEffect !", Object.getOwnPropertyNames(stateProfile.dataUser).length);

    if (
      stateProfile.id === 0 ||
      Object.getOwnPropertyNames(stateProfile.dataUser).length === 0 ||
      Object.getOwnPropertyNames(stateSurvey.data).length === 0
    ) {
      navigate("/");
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageSurvey-Form">
        <main>
          <div className="wrapContent">
            <section id="pTiltlePage">
              <div className="bLogo">
                <img src={img_icoLogo} />
              </div>
            </section>

            <section id="pContentMain">
              <div className="bTitle">
                <h1>{stateSurvey.data?.surveyName}</h1>
              </div>
              <div className="bSurvey">
                {stateSurvey.data?.questions &&
                  stateSurvey.data.questions.map((sqItem, sqIndex) => (
                    <article key={sqIndex + 1}>
                      <div className="bQuestionMain">
                        {sqItem.questionImage !== null && (
                          <div className="bPic">
                            <img src={sqItem.questionImage} alt="" />
                          </div>
                        )}
                        <div className="bQuestion">
                          <span>ข้อที่ {sqIndex + 1} :</span>
                          <div>
                            <h3>{sqItem.questionName}</h3>
                          </div>
                        </div>
                      </div>
                      <div className="bAnswerMain">
                        <div className="bForm">
                          {sqItem.type === 1 && (
                            <div className="control-group">
                              {sqItem?.answers &&
                                sqItem?.answers.map((saItem, saIndex) => (
                                  <div className="bChoice" key={saIndex + 1}>
                                    <label className="control control--checkbox">
                                      {saItem.answerName}
                                      <input
                                        type="checkbox"
                                        value={saIndex}
                                        disabled
                                        checked={saItem.selected}
                                      />
                                      <div className="control__indicator"></div>
                                    </label>
                                    {saItem.answerImage !== null && (
                                      <div className="bPic">
                                        <img src={saItem.answerImage} alt="" />
                                      </div>
                                    )}
                                    {saItem.isText === 1 && (
                                      <div className="bTextarea">
                                        <textarea
                                          disabled
                                          defaultValue={saItem.answerText}
                                        ></textarea>
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </div>
                          )}
                          {sqItem.type === 2 && (
                            <div className="control-group">
                              {sqItem?.answers &&
                                sqItem?.answers.map((saItem, saIndex) => (
                                  <div className="bChoice" key={saIndex + 1}>
                                    <label className="control control--radio">
                                      {saItem.answerName}
                                      <input
                                        type="radio"
                                        value={saIndex}
                                        disabled
                                        checked={saItem.selected}
                                      />
                                      <div className="control__indicator"></div>
                                    </label>
                                    {saItem.answerImage !== null && (
                                      <div className="bPic">
                                        <img src={saItem.answerImage} alt="" />
                                      </div>
                                    )}
                                    {saItem.isText === 1 && (
                                      <div className="bTextarea">
                                        <textarea
                                          disabled
                                          defaultValue={saItem.answerText}
                                        ></textarea>
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </div>
                          )}
                          {sqItem.type === 3 && (
                            <div className="control-group">
                              {sqItem?.answers &&
                                sqItem?.answers.map((saItem, saIndex) => (
                                  <div className="bChoice" key={saIndex + 1}>
                                    <span className="checked-sequence">{saItem.selectionOrder}</span>
                                    <label className="control control--checkbox">
                                      {saItem.answerName}
                                      <input
                                        type="checkbox"
                                        value={saIndex}
                                        disabled
                                        checked={saItem.selected}
                                      />
                                      <div className="control__indicator"></div>
                                    </label>
                                    {saItem.answerImage !== null && (
                                      <div className="bPic">
                                        <img src={saItem.answerImage} alt="" />
                                      </div>
                                    )}
                                    {saItem.isText === 1 && (
                                      <div className="bTextarea">
                                        <textarea
                                          disabled
                                          defaultValue={saItem.answerText}
                                        ></textarea>
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </article>
                  ))}
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <button
                  className="btn outline"
                  onClick={(e) => {
                    resetDataSurvey();
                    handleGotoPage(e);
                  }}
                  data-page="survey"
                >
                  ย้อนกลับ
                </button>
                {/* <button className="btn" type="submit">
                    ต่อไป
                  </button> */}
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default SurveyHistory;

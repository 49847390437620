import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Chart } from "react-google-charts";
import moment from "moment";

const ChartHealthInfo = ({
  typeValue,
  textValue,
  typeFilter, // "year", "yearPeriod", "sixMonth"
  filterValue,
  healthValue,
}) => {
  const stateProfile = useSelector((state) => state.profile);

  /* Cal age from birth date */
  const calculateAge = (birthDate) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();

    // ตรวจสอบว่าครบวันเกิดหรือยัง
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      age--;
    }

    return age;
  };
  /* End Cal age from birth date */

  /* Handle health value color in tooltip */
  const handleHealthValueColor = (
    _typeValue,
    _textValue,
    _value,
    _fontSize = ""
  ) => {
    // console.log(_typeValue, _value);

    let _ele = "";
    let _val = 0;

    const _age = calculateAge(stateProfile.dataUser.birthDate);
    const _gender = stateProfile.gender;

    switch (_typeValue) {
      case "bmi":
        _val = parseFloat(_value);

        if (_val < 18.5) {
          _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
        } else if (_val >= 18.5 && _val <= 22.99) {
          _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
        } else if (_val >= 23 && _val <= 24.99) {
          _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
        } else if (_val >= 25) {
          _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
        }
        break;

      case "pressure":
        _val = parseFloat(_value);

        if (_textValue === "ความดันตัวบน") {
          if (_val <= 100) {
            _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
          } else if (_val >= 101 && _val <= 129) {
            _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
          } else if (_val >= 130 && _val <= 139) {
            _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
          } else if (_val >= 140) {
            _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
          }
        } else if (_textValue === "ความดันตัวล่าง") {
          if (_val <= 70) {
            _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
          } else if (_val >= 71 && _val <= 84) {
            _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
          } else if (_val >= 85 && _val <= 89) {
            _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
          } else if (_val >= 90) {
            _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
          }
        }
        break;

      case "sugar":
        _val = parseFloat(_value);

        if (_val < 140) {
          _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
        } else if (_val >= 140 && _val <= 199.99) {
          _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
        } else if (_val >= 200) {
          _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
        }
        break;

      case "visceralFat":
        _val = parseFloat(_value);

        if (_val <= 9) {
          _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
        } else if (_val >= 10 && _val <= 14) {
          _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
        } else if (_val >= 15 && _val <= 25) {
          _ele = `<strong style="color: #f5854e; ${_fontSize}">${_val}</strong></span>`;
        } else if (_val > 25) {
          _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
        }
        break;

      case "fat":
        _val = parseFloat(_value);

        if (_gender === "m") {
          if (_age >= 20 && _age <= 29) {
            if (_val < 13) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 13 && _val <= 20) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 20.1 && _val <= 31) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 31) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          } else if (_age >= 30 && _age <= 39) {
            if (_val < 14) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 14 && _val <= 21) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 21.1 && _val <= 24) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 24) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          } else if (_age >= 40 && _age <= 49) {
            if (_val < 16) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 16 && _val <= 23) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 23.1 && _val <= 26) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 26) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          } else if (_age >= 50 && _age <= 59) {
            if (_val < 17) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 17 && _val <= 24) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 24.1 && _val <= 27) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 27) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          } else if (_age >= 60) {
            if (_val < 18) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 18 && _val <= 25) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 25.1 && _val <= 28) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 28) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          }
        } else {
          if (_age >= 20 && _age <= 29) {
            if (_val < 19.10) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 19.10 && _val <= 28.00) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 28.10 && _val <= 31.00) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 31.00) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          } else if (_age >= 30 && _age <= 39) {
            if (_val < 20.10) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 20.10 && _val <= 29.00) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 29.10 && _val <= 32.00) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 32.00) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          } else if (_age >= 40 && _age <= 49) {
            if (_val < 21.10) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 21.10 && _val <= 30.00) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 30.10 && _val <= 33.00) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 33.00) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          } else if (_age >= 50 && _age <= 59) {
            if (_val < 22.10) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 22.10 && _val <= 31.00) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 31.10 && _val <= 34.00) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 34.00) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          } else if (_age >= 60) {
            if (_val < 23.10) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 23.10 && _val <= 32.00) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 32.10 && _val <= 35.00) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 35.00) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          }
        }
        break;

      case "muscle":
        _val = parseFloat(_value);

        if (_gender === "m") {
          if (_age >= 20 && _age <= 29) {
            if (_val < 64.25) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 64.25 && _val <= 67.25) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 67.26 && _val <= 74.25) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 74.25) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          } else if (_age >= 30 && _age <= 39) {
            if (_val < 63.25) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 63.25 && _val <= 66.25) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 66.26 && _val <= 73.25) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 73.25) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          } else if (_age >= 40 && _age <= 49) {
            if (_val < 61.25) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 61.25 && _val <= 64.25) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 64.26 && _val <= 71.25) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 71.25) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          } else if (_age >= 50 && _age <= 59) {
            if (_val < 60.25) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 60.25 && _val <= 63.25) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 63.26 && _val <= 70.25) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 70.25) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          } else if (_age >= 60) {
            if (_val < 59.25) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 59.25 && _val <= 62.25) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 62.26 && _val <= 69.25) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 69.25) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          }
        } else {
          if (_age >= 20 && _age <= 29) {
            if (_val < 56.26) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 56.26 && _val <= 59.25) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 59.26 && _val <= 68.25) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 68.25) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          } else if (_age >= 30 && _age <= 39) {
            if (_val < 55.25) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 55.25 && _val <= 58.25) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 58.26 && _val <= 67.25) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 67.25) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          } else if (_age >= 40 && _age <= 49) {
            if (_val < 54.26) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 54.26 && _val <= 57.25) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 57.26 && _val <= 66.25) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 66.25) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          } else if (_age >= 50 && _age <= 59) {
            if (_val < 53.26) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 53.26 && _val <= 56.25) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 56.26 && _val <= 65.25) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 65.25) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          } else if (_age >= 60) {
            if (_val < 52.26) {
              _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 52.26 && _val <= 55.25) {
              _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val >= 55.26 && _val <= 54.25) {
              _ele = `<strong style="color: #fac515; ${_fontSize}">${_val}</strong></span>`;
            } else if (_val > 54.25) {
              _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
            }
          }
        }
        break;

      case "bone":
        _val = parseFloat(_value);

        if (_gender === "m") {
          if (_val > 3.11) {
            _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
          } else if (_val >= 2.41 && _val <= 3.1) {
            _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
          } else if (_val <= 2.4) {
            _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
          }
        } else {
          if (_val > 2.21) {
            _ele = `<strong style="color: #4fb8f9; ${_fontSize}">${_val}</strong></span>`;
          } else if (_val >= 1.71 && _val <= 2.20) {
            _ele = `<strong style="color: #66c61c; ${_fontSize}">${_val}</strong></span>`;
          } else if (_val <= 1.70) {
            _ele = `<strong style="color: #f85b56; ${_fontSize}">${_val}</strong></span>`;
          }
        }
        break;

      default:
        break;
    }

    return _ele;
  };

  const customTooltipContent = (
    _typeValue,
    _textValue,
    _typeFilter,
    _date,
    _value,
    _data = []
  ) => {
    // console.log(_typeValue, _textValue, _typeFilter, _date, _value, _data);

    return `
      <div style="padding: 10px; min-width: 110px;">
        <strong style="font-size: 11px;">${moment(_date).format("MMM YYYY")}
        </strong>
        <br/>
        <span style="display: block; padding-top: 3px;">${`${_textValue}: ${handleHealthValueColor(
          _typeValue,
          _textValue,
          _value
        )}`}
        </span>
        ${
          _data.length > 0
            ? `<br/>
        <ul style="list-style-type: none; padding: 0px; margin: 0px;">
          ${_data
            .map((item) => {
              if (
                moment(item.date).format("YYYY") ===
                  moment(_date).format("YYYY") &&
                moment(item.date).format("MM") === moment(_date).format("MM")
              ) {
                return `<li style="list-style: none; margin-bottom: 3px;"><div style="display: flex; gap: 5px; white-space: nowrap;">${moment(
                  item.date
                ).format("DD/MM/YYYY HH:ss:MM")}: ${handleHealthValueColor(
                  _typeValue,
                  _textValue,
                  item.value
                )}</div></li>`;
              }
            })
            .join("")}
          </ul>`
            : ""
        }
      </div>
    `;
  };
  /* End Handle health value color in tooltip */

  /* First event render */
  const [data, setData] = useState([]);
  const [vMinValue, setVMinValue] = useState({
    left: 0,
    right: 0
  });

  const [moreOption, setMoreOption] = useState({});
  const [ticks, setTicks] = useState([]);

  const options = {
    // title: "...",
    curveType: "function",
    legend: { position: "none" },
    colors: ["#4BC0C0"],
    vAxis: {
      // title: "...",
      minValue: vMinValue.left,
    },
    pointSize: 5,
    ...moreOption,
    tooltip: {
      isHtml: true,
      // trigger: 'selection'
    },
    chartArea: {
      // left: 40,
      width: "86%",
    },
    width: "100%",
  };

  // useEffect(() => {
  //   //   console.log("stateProfile", stateProfile);
  // }, []);
  /* End First event render */

  /* Update vAxis min value */
  const updateVMinValue = (key, value) => {
    setVMinValue((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  /* End Update vAxis min value */

  useEffect(() => {
    // console.log(
    //   typeValue,
    //   textValue,
    //   typeFilter, // "year", "yearPeriod", "sixMonth"
    //   filterValue,
    //   healthValue
    // );

    switch (typeFilter) {
      case "year":
        const monthsInYear = Array.from({ length: 12 }, (_, i) =>
          moment(`${filterValue}-01-01`).month(i).format()
        );
        // console.log(monthsInYear);

        if (typeValue === "pressure") {
          setData([
            [typeFilter, "ความดันตัวบน", "ความดันตัวล่าง"],
            ["", 0, 0],
          ]);

          setMoreOption({
            hAxis: {
              // title: "...",
              format: "",
              ticks: ticks,
              gridlines: { count: 12 },
              slantedText: true,
              slantedTextAngle: 45,
              // textStyle: {
              //   fontSize: 10, // Smaller font to fit more labels
              //   color: "#555",
              // },
              showTextEvery: 1,
            },
            vAxes: {
              0: {
                // title: "",
                // titleTextStyle: { color: "red" },
                minValue: vMinValue.left,
                textStyle: { color: "#4BC0C0" },
              },
              1: {
                // title: "",
                // titleTextStyle: { color: "red" },
                minValue: vMinValue.right,
                textStyle: { color: "#f26722" },
              },
            },
            series: {
              0: { targetAxisIndex: 0, color: "#4BC0C0" },
              1: { targetAxisIndex: 1, color: "#f26722" },
            },
          });

          if (healthValue !== null) {
            let _tmpDataYear = [
              [
                typeFilter,
                "ความดันตัวบน",
                { type: "string", role: "tooltip", p: { html: true } },
                "ความดันตัวล่าง",
                { type: "string", role: "tooltip", p: { html: true } },
              ],
            ];
            let _tmpTicks = [];
            let _tmpMonth = [];

            for (let i = 0; i < monthsInYear.length; i++) {
              for (
                let k = 0;
                k < Object.keys(healthValue.average).length;
                k++
              ) {
                if (
                  moment(monthsInYear[i]).format("MM") ===
                  healthValue.average[k].month
                ) {
                  const date = new Date(
                    `${filterValue}-${healthValue.average[k].month}-01`
                  );
                  const yyyy = date.getFullYear();
                  const mm = date.getMonth();
                  const dd = date.getDate();

                  if (
                    healthValue.average[k].sbpValue !== null &&
                    healthValue.average[k].dbpValue !== null
                  ) {
                    _tmpDataYear = [
                      ..._tmpDataYear,
                      [
                        new Date(yyyy, mm, dd),
                        healthValue.average[k].sbpValue,
                        customTooltipContent(
                          typeValue,
                          "ความดันตัวบน",
                          typeFilter,
                          date,
                          healthValue.average[k].sbpValue,
                          healthValue.data.sbp
                        ),
                        healthValue.average[k].dbpValue,
                        customTooltipContent(
                          typeValue,
                          "ความดันตัวล่าง",
                          typeFilter,
                          date,
                          healthValue.average[k].dbpValue,
                          healthValue.data.dbp
                        ),
                      ],
                    ];
                  }
                }
              }

              if (
                typeof _tmpMonth.find(
                  (m) => m === moment(monthsInYear[i]).format("MMM")
                ) === "undefined"
              ) {
                _tmpMonth.push(moment(monthsInYear[i]).format("MMM"));
              } else {
                _tmpMonth.push("");
              }

              _tmpTicks.push({
                v: new Date(monthsInYear[i]),
                f: _tmpMonth[i],
              });
            }

            setData(_tmpDataYear);

            updateVMinValue("left", Math.min(..._tmpDataYear.slice(1).map(row => row[1])));
            updateVMinValue("right", Math.min(..._tmpDataYear.slice(1).map(row => row[3])));

            setMoreOption({
              ...moreOption,
              hAxis: {
                ...moreOption.hAxis,
                ticks: _tmpTicks,
              },
            });
          }
        } else {
          setData([
            [typeFilter, textValue],
            ["", 0],
          ]);

          setMoreOption({
            hAxis: {
              // title: "...",
              format: "",
              ticks: ticks,
              gridlines: { count: 12 },
              slantedText: true,
              slantedTextAngle: 45,
              // textStyle: {
              //   fontSize: 10, // Smaller font to fit more labels
              //   color: "#555",
              // },
              showTextEvery: 1,
            },
          });

          if (healthValue !== null) {
            let _tmpDataYear = [
              [
                typeFilter,
                textValue,
                { type: "string", role: "tooltip", p: { html: true } },
              ],
            ];
            let _tmpTicks = [];
            let _tmpMonth = [];

            for (let i = 0; i < monthsInYear.length; i++) {
              for (
                let k = 0;
                k < Object.keys(healthValue.average).length;
                k++
              ) {
                if (
                  moment(monthsInYear[i]).format("MM") ===
                  healthValue.average[k].month
                ) {
                  const date = new Date(
                    `${filterValue}-${healthValue.average[k].month}-01`
                  );
                  const yyyy = date.getFullYear();
                  const mm = date.getMonth();
                  const dd = date.getDate();

                  if (healthValue.average[k].value !== null) {
                    _tmpDataYear = [
                      ..._tmpDataYear,
                      [
                        new Date(yyyy, mm, dd),
                        healthValue.average[k].value,
                        customTooltipContent(
                          typeValue,
                          textValue,
                          typeFilter,
                          date,
                          healthValue.average[k].value,
                          healthValue.data
                        ),
                      ],
                    ];
                  }
                }
              }

              if (
                typeof _tmpMonth.find(
                  (m) => m === moment(monthsInYear[i]).format("MMM")
                ) === "undefined"
              ) {
                _tmpMonth.push(moment(monthsInYear[i]).format("MMM"));
              } else {
                _tmpMonth.push("");
              }

              _tmpTicks.push({
                v: new Date(monthsInYear[i]),
                f: _tmpMonth[i],
              });
            }
            // console.log(_tmpDataYear, _tmpTicks);

            setData(_tmpDataYear);

            updateVMinValue("left", Math.min(..._tmpDataYear.slice(1).map(row => row[1])));

            setMoreOption({
              ...moreOption,
              hAxis: {
                ...moreOption.hAxis,
                ticks: _tmpTicks,
              },
            });
          }
        }
        break;

      case "yearPeriod":
        const yearRange = Array.from(
          { length: filterValue.yearEnd - filterValue.yearStart + 1 },
          (_, i) => filterValue.yearStart + i
        );
        // console.log(yearRange);

        if (typeValue === "pressure") {
          setData([
            [typeFilter, "ความดันตัวบน", "ความดันตัวล่าง"],
            ["", 0, 0],
          ]);

          setMoreOption({
            hAxis: {
              // title: "...",
              format: "",
              ticks: ticks,
              gridlines: { count: 3 },
              slantedText: true,
              slantedTextAngle: 45,
            },
            vAxes: {
              0: {
                // title: "",
                // titleTextStyle: { color: "red" },
                minValue: 0,
                textStyle: { color: "#4BC0C0" },
              },
              1: {
                // title: "",
                // titleTextStyle: { color: "red" },
                minValue: 0,
                textStyle: { color: "#f26722" },
              },
            },
            series: {
              0: { targetAxisIndex: 0, color: "#4BC0C0" },
              1: { targetAxisIndex: 1, color: "#f26722" },
            },
          });

          if (healthValue !== null) {
            let _tmpDataYearPeriod = [
              [
                typeFilter,
                "ความดันตัวบน",
                { type: "string", role: "tooltip", p: { html: true } },
                "ความดันตัวล่าง",
                { type: "string", role: "tooltip", p: { html: true } },
              ],
            ];
            let _tmpTicks = [];
            let _tmpYear = [];

            for (let i = 0; i < yearRange.length; i++) {
              for (
                let k = 0;
                k < Object.keys(healthValue.average).length;
                k++
              ) {
                if (yearRange[i].toString() === healthValue.average[k].year) {
                  const date = new Date(
                    `${healthValue.average[k].year}-${healthValue.average[k].month}-01`
                  );
                  const yyyy = date.getFullYear();
                  const mm = date.getMonth();
                  const dd = date.getDate();

                  if (
                    healthValue.average[k].sbpValue !== null &&
                    healthValue.average[k].dbpValue !== null
                  ) {
                    _tmpDataYearPeriod = [
                      ..._tmpDataYearPeriod,
                      [
                        new Date(yyyy, mm, dd),
                        healthValue.average[k].sbpValue,
                        customTooltipContent(
                          typeValue,
                          "ความดันตัวบน",
                          typeFilter,
                          date,
                          healthValue.average[k].sbpValue,
                          healthValue.data.sbp
                        ),
                        healthValue.average[k].dbpValue,
                        customTooltipContent(
                          typeValue,
                          "ความดันตัวล่าง",
                          typeFilter,
                          date,
                          healthValue.average[k].dbpValue,
                          healthValue.data.dbp
                        ),
                      ],
                    ];
                  }
                }
              }

              if (
                typeof _tmpYear.find((y) => y === yearRange[i]) === "undefined"
              ) {
                _tmpYear.push(yearRange[i]);
              } else {
                _tmpYear.push("");
              }

              _tmpTicks.push({
                v: new Date(yearRange[i], 0, 1),
                f: _tmpYear[i].toString(),
              });
            }

            setData(_tmpDataYearPeriod);

            updateVMinValue("left", Math.min(..._tmpDataYearPeriod.slice(1).map(row => row[1])));
            updateVMinValue("right", Math.min(..._tmpDataYearPeriod.slice(1).map(row => row[3])));

            setMoreOption({
              ...moreOption,
              hAxis: {
                ...moreOption.hAxis,
                ticks: _tmpTicks,
              },
            });
          }
        } else {
          setData([
            [typeFilter, textValue],
            ["", 0],
          ]);

          setMoreOption({
            hAxis: {
              // title: "...",
              format: "",
              ticks: ticks,
              gridlines: { count: 3 },
              slantedText: true,
              slantedTextAngle: 45,
            },
          });

          if (healthValue !== null) {
            let _tmpDataYearPeriod = [
              [
                typeFilter,
                textValue,
                { type: "string", role: "tooltip", p: { html: true } },
              ],
            ];
            let _tmpTicks = [];
            let _tmpYear = [];

            for (let i = 0; i < yearRange.length; i++) {
              for (
                let k = 0;
                k < Object.keys(healthValue.average).length;
                k++
              ) {
                if (yearRange[i].toString() === healthValue.average[k].year) {
                  const date = new Date(
                    `${healthValue.average[k].year}-${healthValue.average[k].month}-01`
                  );
                  const yyyy = date.getFullYear();
                  const mm = date.getMonth();
                  const dd = date.getDate();

                  if (healthValue.average[k].value !== null) {
                    _tmpDataYearPeriod = [
                      ..._tmpDataYearPeriod,
                      [
                        new Date(yyyy, mm, dd),
                        healthValue.average[k].value,
                        customTooltipContent(
                          typeValue,
                          textValue,
                          typeFilter,
                          date,
                          healthValue.average[k].value,
                          healthValue.data
                        ),
                      ],
                    ];
                  }
                }
              }

              if (
                typeof _tmpYear.find((y) => y === yearRange[i]) === "undefined"
              ) {
                _tmpYear.push(yearRange[i]);
              } else {
                _tmpYear.push("");
              }

              _tmpTicks.push({
                v: new Date(yearRange[i], 0, 1),
                f: _tmpYear[i].toString(),
              });
            }
            // console.log(_tmpDataYearPeriod, _tmpTicks);

            setData(_tmpDataYearPeriod);

            updateVMinValue("left", Math.min(..._tmpDataYearPeriod.slice(1).map(row => row[1])));

            setMoreOption({
              ...moreOption,
              hAxis: {
                ...moreOption.hAxis,
                ticks: _tmpTicks,
              },
            });
          }
        }
        break;

      case "sixMonth":
        const last6Months = Array.from({ length: 6 }, (_, i) =>
          moment().subtract(i, "months").startOf("month").format()
        ).reverse();
        // console.log(last6Months);

        if (typeValue === "pressure") {
          setData([
            [typeFilter, "ความดันตัวบน", "ความดันตัวล่าง"],
            ["", 0, 0],
          ]);

          setMoreOption({
            hAxis: {
              // title: "...",
              format: "",
              ticks: ticks,
              gridlines: { count: 6 },
              slantedText: true,
              slantedTextAngle: 45,
              showTextEvery: 1,
            },
            vAxes: {
              0: {
                // title: "",
                // titleTextStyle: { color: "red" },
                minValue: 0,
                textStyle: { color: "#4BC0C0" },
              },
              1: {
                // title: "",
                // titleTextStyle: { color: "red" },
                minValue: 0,
                textStyle: { color: "#f26722" },
              },
            },
            series: {
              0: { targetAxisIndex: 0, color: "#4BC0C0" },
              1: { targetAxisIndex: 1, color: "#f26722" },
            },
          });

          if (healthValue !== null) {
            let _tmpDataSixMonth = [
              [
                typeFilter,
                "ความดันตัวบน",
                { type: "string", role: "tooltip", p: { html: true } },
                "ความดันตัวล่าง",
                { type: "string", role: "tooltip", p: { html: true } },
              ],
            ];
            let _tmpTicks = [];
            let _tmpSixMonth = [];

            for (let i = 0; i < last6Months.length; i++) {
              for (
                let k = 0;
                k < Object.keys(healthValue.average).length;
                k++
              ) {
                if (
                  moment(last6Months[i]).format("MM") ===
                  healthValue.average[k].month
                ) {
                  const date = new Date(
                    `${moment(last6Months[i]).format("YYYY")}-${
                      healthValue.average[k].month
                    }-01`
                  );
                  const yyyy = date.getFullYear();
                  const mm = date.getMonth();
                  const dd = date.getDate();

                  if (
                    healthValue.average[k].sbpValue !== null &&
                    healthValue.average[k].dbpValue !== null
                  ) {
                    _tmpDataSixMonth = [
                      ..._tmpDataSixMonth,
                      [
                        new Date(yyyy, mm, dd),
                        healthValue.average[k].sbpValue,
                        customTooltipContent(
                          typeValue,
                          "ความดันตัวบน",
                          typeFilter,
                          date,
                          healthValue.average[k].sbpValue,
                          healthValue.data.sbp
                        ),
                        healthValue.average[k].dbpValue,
                        customTooltipContent(
                          typeValue,
                          "ความดันตัวล่าง",
                          typeFilter,
                          date,
                          healthValue.average[k].dbpValue,
                          healthValue.data.dbp
                        ),
                      ],
                    ];
                  }
                }
              }

              if (
                typeof _tmpSixMonth.find(
                  (m) => m === moment(last6Months[i]).format("MMM")
                ) === "undefined"
              ) {
                _tmpSixMonth.push(moment(last6Months[i]).format("MMM"));
              } else {
                _tmpSixMonth.push("");
              }

              _tmpTicks.push({
                v: new Date(last6Months[i]),
                f: _tmpSixMonth[i],
              });
            }

            setData(_tmpDataSixMonth);

            updateVMinValue("left", Math.min(..._tmpDataSixMonth.slice(1).map(row => row[1])));
            updateVMinValue("right", Math.min(..._tmpDataSixMonth.slice(1).map(row => row[3])));

            setMoreOption({
              ...moreOption,
              hAxis: {
                ...moreOption.hAxis,
                ticks: _tmpTicks,
              },
            });
          }
        } else {
          setData([
            [typeFilter, textValue],
            ["", 0],
          ]);

          setMoreOption({
            hAxis: {
              // title: "...",
              format: "",
              ticks: ticks,
              gridlines: { count: 6 },
              slantedText: true,
              slantedTextAngle: 45,
              showTextEvery: 1,
            },
          });

          if (healthValue !== null) {
            let _tmpDataSixMonth = [
              [
                typeFilter,
                textValue,
                { type: "string", role: "tooltip", p: { html: true } },
              ],
            ];
            let _tmpTicks = [];
            let _tmpSixMonth = [];

            for (let i = 0; i < last6Months.length; i++) {
              for (
                let k = 0;
                k < Object.keys(healthValue.average).length;
                k++
              ) {
                if (
                  moment(last6Months[i]).format("MM") ===
                  healthValue.average[k].month
                ) {
                  const date = new Date(
                    `${moment(last6Months[i]).format("YYYY")}-${
                      healthValue.average[k].month
                    }-01`
                  );
                  const yyyy = date.getFullYear();
                  const mm = date.getMonth();
                  const dd = date.getDate();

                  if (healthValue.average[k].value !== null) {
                    _tmpDataSixMonth = [
                      ..._tmpDataSixMonth,
                      [
                        new Date(yyyy, mm, dd),
                        healthValue.average[k].value,
                        customTooltipContent(
                          typeValue,
                          textValue,
                          typeFilter,
                          date,
                          healthValue.average[k].value,
                          healthValue.data
                        ),
                      ],
                    ];
                  }
                }
              }

              if (
                typeof _tmpSixMonth.find(
                  (m) => m === moment(last6Months[i]).format("MMM")
                ) === "undefined"
              ) {
                _tmpSixMonth.push(moment(last6Months[i]).format("MMM"));
              } else {
                _tmpSixMonth.push("");
              }

              _tmpTicks.push({
                v: new Date(last6Months[i]),
                f: _tmpSixMonth[i],
              });
            }
            // console.log(_tmpDataSixMonth, _tmpTicks);

            setData(_tmpDataSixMonth);

            updateVMinValue("left", Math.min(..._tmpDataSixMonth.slice(1).map(row => row[1])));

            setMoreOption({
              ...moreOption,
              hAxis: {
                ...moreOption.hAxis,
                ticks: _tmpTicks,
              },
            });
          }
        }
        break;

      default:
        break;
    }
  }, [filterValue, healthValue]);

  useEffect(() => {
    // console.log("data >>", data, vMinValue, moreOption);
  }, [data, vMinValue, moreOption]);

  return (
    <Chart
      chartType="LineChart"
      data={data}
      options={options}
      width="100%"
      height="100%"
      language="th"
      chartLanguage="th"
      loader={<div>Loading...</div>}
    />
  );
};

export default ChartHealthInfo;
